import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/SEO"
import HeroAlt from "../components/HeroAlt"
import CarePrizesBlock from "../components/CarePrizesBlock"
// import FormBlock from "../components/FormBlock"

const Prizespage = () => {
  const data = useStaticQuery(graphql`
    {
      page: mdx(fileAbsolutePath: { regex: "/content/giveaways/" }) {
        body
        frontmatter {
          path
          title
          hero {
            backgroundImage
          }
          giveawayBlock {
            title
            subText
            subTitle
            subTitleAlt
            listItems
            ctaLink
            ctaText
          }
        }
      }
      images: allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativeDirectory: { eq: "assets/images" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              id
              fluid {
                originalName
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
  `)

  const { title, hero, giveawayBlock } = data.page.frontmatter

  const images = data.images.edges.map(data => data.node.childImageSharp.fluid)
  const getImage = name =>
    images.find(({ originalName }) => originalName === name)

  return (
    <>
      <SEO title={title} />

      <HeroAlt title={title} backgroundImage={getImage(hero.backgroundImage)} />

      <CarePrizesBlock
        {...giveawayBlock}
        isVertical={false}
        className="prizes-page__block1"
      />

      {/* <FormBlock {...formBlock} /> */}
    </>
  )
}

export default Prizespage
