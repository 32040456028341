import React from "react"
import PropTypes from "prop-types"
import Button from "./core/Button"
import background from "../assets/images/dark-sun-background.svg"
import sectionBackground from "../assets/images/rect-28.svg"
import { navigate } from "gatsby"

const CarePrizesBlock = ({
  title,
  subText,
  subTitle,
  subTitleAlt,
  listItems,
  ctaLink,
  ctaText,
}) => {
  const prizes = []

  listItems.forEach(item => {
    prizes.push(<li key={item}>{item}</li>)
  })

  return (
    <section
      className="container care-prizes"
      style={{ backgroundImage: `url(${background})` }}
    >
      <div
        className="care-prizes__section-one"
        data-sal="fade"
        data-sal-duration="1000"
        data-sal-easing="ease-out"
      >
        <h2>{title}</h2>
        <p>{subText}</p>
      </div>
      <div
        className="care-prizes__section-two"
        data-sal="fade"
        data-sal-duration="1500"
        data-sal-easing="ease"
        style={{ backgroundImage: `url(${sectionBackground})` }}
      >
        <div className="care-prizes__section-two__copy">
          <h3>
            {subTitle}
            <span>{subTitleAlt}</span>
          </h3>
          <ul>{prizes}</ul>
          <Button
            colour="dark"
            text={ctaText}
            type="link"
            link={ctaLink}
            gatsbyLink={true}
            padding={"standard"}
            onClick={() => {
              navigate("/")
              window.applyLabels(true)
            }}
          />
        </div>
      </div>
    </section>
  )
}

CarePrizesBlock.propTypes = {
  title: PropTypes.string.isRequired,
}

export default CarePrizesBlock
